import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Vitality Hub
			</title>
			<meta name={"description"} content={"Заряди своє життя енергією!"} />
			<meta property={"og:title"} content={"Головна | Vitality Hub"} />
			<meta property={"og:description"} content={"Заряди своє життя енергією!"} />
			<meta property={"og:image"} content={"https://joviluxor.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://joviluxor.com/img/fitness.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://joviluxor.com/img/fitness.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://joviluxor.com/img/fitness.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://joviluxor.com/img/fitness.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://joviluxor.com/img/fitness.png"} />
			<meta name={"msapplication-TileImage"} content={"https://joviluxor.com/img/fitness.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="260px 0 270px 0" background="linear-gradient(180deg,rgba(10, 187, 155, 0.2) 0%,rgba(10, 187, 155, 0.2) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://joviluxor.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 100px 0px 100px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до Vitality Hub!
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--light"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Відкрийте для себе фітнес-середовище, де ваші цілі у сфері здоров'я досягаються з ентузіазмом та підтримкою. Vitality Hub - це сучасний, чистий та енергійний простір для занять фітнесом з найсучаснішими тренажерами та під керівництвом професіоналів.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="--color-darkL2"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Купити Абонемент
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-3">
			<Text
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Чому варто обрати Vitality Hub?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Передове обладнання: 
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Найсучасніші тренажери та інструменти для ефективних тренувань.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Індивідуальні фітнес-подорожі: 
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Персоналізовані фітнес-плани, які зосереджені на ваших конкретних потребах і прагненнях.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 0 25px"
					sm-padding="0px 0 0 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Зосередженість на спільноті: 
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Фітнес-спільнота, яка мотивує та підтримує, роблячи тренування веселими та цікавими.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://joviluxor.com/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://joviluxor.com/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					У Vitality Hub ми прагнемо забезпечити першокласний фітнес-досвід у гостинній атмосфері. Від широкого асортименту кардіо- та силових тренажерів до групових занять, які підтримують мотивацію, ми гарантуємо, що кожен візит до нас заряджає вас енергією. Наш персонал добре обізнаний і завжди готовий допомогти вам оптимізувати тренування, відстежувати ваш прогрес та досягти ваших цілей у сфері здоров'я та фітнесу.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});